import React, { useState, useEffect } from 'react';
import { ClimbingBoxLoader } from "react-spinners";
import { isMobile } from 'react-device-detect';
import { zoom } from "./vendor/zoom";
import smoothscroll from 'smoothscroll-polyfill';
import { useTranslation } from 'react-i18next';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import Joyride from 'react-joyride';
import { mouseScroller } from './utils/mouseScroller';
import { useLocation } from "react-router-dom";
import Content from './components/content';
import Clock from './components/clock';
import Player from './components/player';
import SimpleFrame from './components/simpleFrame';
import Switcher from './components/switcher';
import Lamp from './components/lamp';
import Modal from './components/modal'
import SiteWalkThrough from './vendor/siteWalkThrought';
import LandingModal from './components/landingModal';
import { FaInfo  } from 'react-icons/fa';

smoothscroll.polyfill();

const App = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const initWidth = isMobile ? window.screen.width : window.innerWidth
  const siteWidth = 2886;
  const arrowSize = 55;
  const [isSiteWalkThrought, setIsSiteWalkThrought] = useState(false);
  const [screenWidth, setScreenWidth] = useState(initWidth);
  const [outViewWidth, setOutViewWidth] = useState(((siteWidth + arrowSize) - screenWidth) / 2);
  const [isWelcomePageActive, setIsWelcomePageActive] = useState(false);
  const [isLandingModalActive, setIsLandingModalActive] = useState(true);
  let maxLeft = siteWidth - screenWidth

  useEffect(() => {
    const loader = document.getElementById("loader");
    if (loader) {
      loader.classList.add("hide");
    }
    if (location.pathname !== '/') {
      setTimeout(() => {
        const element = document.getElementById(location.pathname.split('/')[1])
        if (element) {
          element.click();
          if (!isMobile) {
            mouseScroller(outViewWidth);
          }
        }
      }, 1000)
    } else if (!isMobile) {
      //setIsWelcomePageActive(true);
      //window.scroll({ top: 0, left: outViewWidth-60, behavior: 'smooth' });
      mouseScroller(outViewWidth);
      setIsSiteWalkThrought(handleWalkThroughCookie);
    } else {
      window.scroll({ top: 210, left: outViewWidth-60, behavior: 'smooth' });
      //setIsWelcomePageActive(true);
      setIsSiteWalkThrought(handleWalkThroughCookie);
      //mouseScroller(0);
    }
  }, [])

  const handleWalkThroughCookie = () => {
    const cookieValue = getCookie('siteWalkThrough');
    if (cookieValue == '1') {
      return false
    } else {
      const expiresDate = new Date()
      expiresDate.setMonth(expiresDate.getMonth() + 3)
      document.cookie = 'siteWalkThrough=1; expires='+expiresDate.toUTCString();
      return true
    }
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  window.addEventListener('resize', () => {
    setScreenWidth(window.innerWidth);
    //maxLeft = siteWidth - window.innerWidth
  })

  const handleClickStep2 = () => {
    setTimeout(() => {
      const container = document.querySelector('#container');
      if (container) {
        document.querySelector('#container').click();
        const x = 1901 - (window.innerWidth / 2)
        const y = 256 - (window.innerHeight / 2)
        window.scroll({ left: x, top: y, behavior: 'smooth' });
        document.querySelector('.swtWrapper').style.display = 'block';
      }
    }, 10);
  }

  const bodyCss = `body, .App, #container{width: ${siteWidth}px}`
  const loaderCss = `
  position: absolute;
  left: 10%;
  top: 30%`
  const joyRide = {
    steps: [
      {
        content: t('step1'),
        position: 'middle',
        target: '.monitor',
        title: t('welcome'),
        alwaysVisible: 'true',
        handleClickOnSpotLight: () => {
          setTimeout(() => {
            const container = document.querySelector('#about .contentContainer');
            if (container) {
              container.click();
            }
            document.querySelector('.swtWrapper').style.display = 'none';
          }, 10);
        }
      },
      {
        target: '#closeElement',
        content: t('step2'),
        position: 'middle',
        handleClickOnSpotLight: handleClickStep2
      },
      {
        target: '#contact',
        content: t('step3'),
        position: 'middle',
        handleClickOnSpotLight: () => {
          setTimeout(() => {
            const container = document.querySelector('#contact .contactMain');
            if (container) {
              container.click();
              document.querySelector('.swtWrapper').style.display = 'none';
            }
          }, 10);
        }
      },
      {
        target: '#lightButton',
        content: t('step4'),
        position: 'middle',
        handleClickOnSpotLight: () => {
          setTimeout(() => {
            const container = document.querySelector('#lightButton');
            if (container) {
              container.click();
            }
            setIsWelcomePageActive(true);
            setIsSiteWalkThrought(false);
          }, 10);
        }
      },
    ]
  };

/*
 const handleHoverIn = (element, hoverImg) => {
    element.target.src = hoverImg;
    element.target.style.height = '83px';
    element.target.style.top = '685px';
  }

  const handleHoverOut = (element, originImg) => {
    element.target.src = originImg;
    element.target.style.height = '63px';
    element.target.style.top = '705px';
  } */

  const handleWelcomeClose = () => {
    setIsWelcomePageActive(false);
  }

  return (
    outViewWidth === 0 ? (
      <ClimbingBoxLoader css={loaderCss} size={52} />
    ) :
      (
        <div className="App">
          <style>{bodyCss}</style>
          {/* <div className='darkLayer'></div> */}
          <div id="container" className={isMobile ? "isMobile" : ""}>
            {/* <FaArrowCircleUp
              size={arrowSize}
              className='arrow'
            /> */}
            {/* {isLandingModalActive && (
              <LandingModal handleClose={() => setIsLandingModalActive(false)} />
            )} */}
            {isWelcomePageActive && <Modal
              title={t('welcomeTitle')}
              description={t('welcomeDescription')}
              handleClose={handleWelcomeClose}
            />}
            {/* {!isLandingModalActive && <div className='modalOpener' onClick={() => setIsLandingModalActive(true)}>
              <FaInfo size={25}/>
            </div>} */}
            <IoIosCloseCircleOutline className='closeContentButton' onClick={() => {zoom.out()}} />
            {isSiteWalkThrought && <SiteWalkThrough
              steps={joyRide.steps}
            />}
            <Content
              position={{ left: '102px', top: '320px' }}
              type='purpleL purpleFrame'
              title='Concept Architect'
              text={t('conceptText')}
              slug='ca'
              //rawLinks={[{ text: "Web", url: "https://devdemo.hu/", iconType: "web" }]}
              images={[
                require('./images/references/ca.png').default,
                require('./images/references/ca-min.gif').default,
                require('./images/references/ca2.png').default,
                require('./images/references/ca2-min.gif').default,
              ]}
              logo={require('./images/references/ca_logo.png').default}
            />
            <Content
              position={{ left: '384px', top: '344px' }}
              type='yellowP'
              title='Wopes - térkép'
              text={t('wopesText')}
              slug='wopes'
              images={[
                require('./images/references/wopes2.png').default,
                require('./images/references/wopes.png').default,
              ]}
              logo={require('./images/references/site_logo.png').default}
            />
            <div id="closeElement"></div>
            <Content
              position={{ left: '654px', top: '292px' }}
              type='greenPBig greenFrame'
              text={t('promoText')}
              slug='promo'
              title={t('promoTitle')}
              logo={require("./images/references/info-icon.png").default}
            />
            <Content
              position={{ left: '1310px', top: '122px' }}
              images={[
                require('./images/references/peting.png').default,
                require('./images/references/peting2.png').default,
                require('./images/references/peting3.png').default,
              ]}
              rawLinks={[
                { text: "Play", url: "https://peting.hu", iconType: "android" },
                { text: "AppStore", url: "https://peting.hu", iconType: "apple" },
                { text: "Web", url: "https://peting.hu", iconType: "web" }
              ]}
              imageType="mobile"
              title='PETing'
              text={t('petingText')}
              type='yellowP'
              slug='peting'
              logo={require('./images/references/peting_logo.png').default}
            />
            <div className='logo'>
              <div></div>
              <img alt="logo" src={require('./images/logo.svg')} />
            </div>
            <Content
              position={{ left: '2251px', top: '474px' }}
              type='greenP greenFrame'
              images={[
                require('./images/references/cw_jobs1.gif').default,
                require('./images/references/cw_jobs2.png').default,
                require('./images/references/cw_jobs3.png').default,
              ]}
              imageType="web"
              title="CW Jobs"
              text={t('cwJobsText')}
              slug='cw'
              rawLinks={[{ text: "Web", url: "https://www.cwjobs.co.uk/insights/environmental-impact-of-emails/", iconType: "web" }]}
              logo={require('./images/references/cwjobs_logo.png').default}
            />
            <Content
              position={{ left: '2459px', top: '164px' }}
              type='blueL blueFrame'
              slug='empty'
            />
            {/* Design elements */}
            <img
              alt="shelf"
              src={require("./images/svg/konyvespolc.svg").default}
              className="shelf element"
            />

            <img
              alt="wallGrid"
              src={require("./images/png/fali-racs@3x.png").default}
              className="wallGrid element"
            />

            <SimpleFrame />

            <Content
              position={{ left: '1839px', top: '256px' }}
              type='contact'
              title='contact'
              slug='contact'
            />

            <img
              alt="palmphoto"
              src={require("./images/svg/palmaskep.svg")}
              className="palmphoto element"
            />

            <Content
              type='calendar'
              slug='calendar'
            />

            <img
              alt="pens"
              src={require("./images/svg/tolltarto.svg").default}
              className="pens element"
            />

            <div
              className="asztalarnyek element"
            >
              <img
                alt="desk"
                src={require("./images/png/asztal-copy@3x.png").default}
                className="desk"
              />
            </div>

           <Lamp />

            <img
              alt="flower"
              src={require("./images/svg/kaktusz.svg").default}
              className="flower element"
            />

            <img
              alt="speaker_l"
              src={require("./images/svg/hangszoro.svg")}
              className="speaker-l element"
            />
            <Player
              urls={[
                'https://cdn.pixabay.com/download/audio/2022/01/20/audio_743615ade2.mp3?filename=everything-feels-new-15241.mp3',
                'https://cdn.pixabay.com/download/audio/2021/12/22/audio_9da2a60074.mp3?filename=penguinmusic-modern-chillout-12641.mp3',
                'https://cdn.pixabay.com/download/audio/2021/11/25/audio_91b32e02f9.mp3?filename=jazzy-abstract-beat-11254.mp3'
              ]}
            />

            <img
              alt="speaker_r"
              src={require("./images/png/hagnszoro-copy@3x.png").default}
              className="speaker-r element"
            />

            <div
              alt="monitor"
              className="monitor element"
            >
              <Content
                position={{}}
                title={t("introductionTitle")}
                images={[require("./images/en.jpg").default]}
                text={t('introductionText')}
                type='monitor'
                slug='about'
                logo={require("./images/logo.svg")}
              />
            </div>

            <img
              alt="coffee"
              src={require("./images/svg/kave.svg").default}
             /*  onMouseOver={(element) => handleHoverIn(element, require("./images/animations/kave2.gif").default)}
              onMouseOut={(element) => handleHoverOut(element, require("./images/png/kave@3x.png").default)} */
              className="coffee element"
            />

            <img
              alt="books"
              src={require("./images/png/konyvek@3x.png").default}
              className="books element"
            />

            <img
              alt="plant-big"
              src={require("./images/svg/jobboldalinoveny.svg").default}
              className="plantBig element"
            />

            <img
              alt="plant"
              src={require("./images/svg/baloldalinoveny.svg").default}
              className="plant element"
            />

            <img
              alt="chair"
              src={require("./images/svg/szek.svg")}
              className="chair element"
            />

            <Switcher />
            <img
              alt="HU EN"
              src={require("./images/switcher_alt.svg").default}
              className="languageSelector"
            />
            {/* <span className="languageSelector">
              HU
              ENG
            </span> */}
            <Clock />
          </div>
        </div>
    )
  );
}

export default App;
