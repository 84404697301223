import React, { useEffect, useState } from 'react';
import Swipe from 'react-easy-swipe';
import { AiOutlineCloseCircle, AiOutlineArrowLeft, AiOutlineArrowRight,  } from 'react-icons/ai';

const Gallery = ({ images, imageIndex, handleClose, handlePrevision, handleNext }) => {
  const handlePressArrowButtons = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'ArrowLeft') {
      handlePrevision();
    } else if (e.key === 'ArrowRight') {
      handleNext();
    }
  }

  useEffect(() => {
    //document.addEventListener("keydown", handlePressArrowButtons, true);
  }, [])

  return (
    <div className='gallery'>
      <Swipe
        onSwipeLeft={handlePrevision}
        onSwipeRight={handleNext}
        className="gallerySwipeContainer"
      >
        {images.length > 1 && (
          <AiOutlineArrowLeft size='55' className='galleryArrow leftArrow' onClick={handlePrevision}/>
        )}
        <button onClick={() => {
          document.removeEventListener("keydown", handlePressArrowButtons, true)
          handleClose();
        }}>
          <AiOutlineCloseCircle size='65'/>
        </button>
        <img alt={images[imageIndex]} src={images[imageIndex]} />
        {images.length > 1 && (
          <AiOutlineArrowRight size='55' className='galleryArrow rightArrow' onClick={handleNext}/>
          )}
      </Swipe>
    </div>
  )
}

export default Gallery;
