import React from 'react'

const MobileFrame = ({
  image,
  alt,
  handleGalleryOpen
}) => {
  return (
    <div className="mobileContainer">
      <div className="phone">
        <img onClick={handleGalleryOpen} src={image} alt={alt} />
      </div>
    </div>
  )
}

export default MobileFrame;
