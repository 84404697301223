import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { BrowserRouter } from 'react-router-dom';
import AlertTemplate from 'react-alert-template-basic'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./localizations/i18n";

const options = {
  position: positions.MIDDLE,
  timeout: 5000,
  transition: transitions.SCALE
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
