import React from 'react'
import {
  FaFacebookF,
  FaLinkedin,
  FaPinterest,
  FaEnvelope,
} from 'react-icons/fa';

const AboutMe = ({
  title,
  text,
  image,
  links,
}) => {
  const iconColor = '#fe4040';
  return (
    <div className='contentContainer'>
      {title && <h5><span>{title}</span></h5>}
      <div className='textAndImage'>
        <div className='text'>{text}</div>
        <div>
          {image && <img alt={title} src={image} />}
          <div className='socialBar'>
            <a alt="facebook" href="https://www.facebook.com/peter.kozari/" target="_blank" rel="noreferrer">
              <FaFacebookF size={11} color={iconColor} />
            </a>
            <a alt="linkedin" href="https://www.linkedin.com/in/peter-kozari/" target="_blank" rel="noreferrer">
              <FaLinkedin size={11} color={iconColor} />
            </a>
            <a href="mailto:info@dewises.com">
              <FaEnvelope size={11} color={iconColor} />
            </a>
            {/* <FaPinterest size={11} color={iconColor} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe;