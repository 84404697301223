import React, {useEffect, useState} from 'react';
import { zoom } from "../vendor/zoom";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Links from "../components/links"
import AboutMe from './aboutMe';
import Gallery from './gallery';
import MobileFrame from './mobileFrame'
import Contact from './contact';
import Consultation from './consultation';

const Content = ({
  position,
  images,
  text,
  title,
  rawLinks,
  type,
  imageType,
  logo,
  slug,
}) => {
  const navigate = useNavigate();
  const container = document.getElementById('container')
  const cssClasses = 'element frame zoomable ' + type;
  const [elementPosition, setElementPosition] = useState({
    left: '-100%',
    top: '-100%',
  });
  const [inZoom, setInZoom] = useState(false)
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  window.addEventListener('popstate', function (event) {
    if (window.location.pathname === "/") {
      zoom.out();
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
      setInZoom(false)
      const currentContentContainer = document.getElementById(slug);
      if (currentContentContainer) {
        const frameCover = currentContentContainer.getElementsByClassName('frameCover')[0]
        if (frameCover) {
          frameCover.style.display = 'flex';
          frameCover.classList.remove('opacity0');
          frameCover.classList.add('opacity1');
        }
      }
    } else {
      //handleZoomIn({}, document.getElementById(slug))
    }
  });

  useEffect(() => {
    setElementPosition(position);

  },[]);
  const {t} = useTranslation();

  const handleZoomOut = (event, zoomedElement) => {
    const parent = event.target.closest('.zoomable')
    if (
      parent === null
      && !event.target.classList.contains('react-calendar__tile')
      && event.target.tagName !== 'ABBR'
    ) {
      if (document.getElementById('step1')) {
        document.querySelector('.pinner').click();
        document.querySelector('.swtWrapper').style.display = 'block';
      }
      if (document.querySelector('.zoomLevel #step3')) {
        console.log('step3')
        const x = 929 - (window.innerWidth / 2)
        const y = 778 - (window.innerHeight / 2)
        window.scroll({ left: x, top: y, behavior: 'smooth' });
      }
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
      setInZoom(false)
      zoom.out();
      zoomedElement.classList.remove('inZoom');
      container.classList.remove('zoomLevel')
      navigate('/');

      const currentContentContainer = document.getElementById(slug);
      if (currentContentContainer) {
        const frameCover = currentContentContainer.getElementsByClassName('frameCover')[0]
        if (frameCover) {
          frameCover.style.display = 'flex';
          frameCover.classList.remove('opacity0');
          frameCover.classList.add('opacity1');
        }
      }
    }
  }

  const handleZoomIn = (event, element) => {
    let parent = {}
    if (element) {
      parent = element
    } else {
      parent = event.target.closest('.zoomable');
    }
    if (!inZoom) {
      zoom.to({
        element: parent,
        pan: false
      });
      if (slug === 'promo') {
        if (typeof window !== "undefined") {
          if (window.fbq != null) { 
            window.fbq('track', 'ViewContent');
          }
        }
      }
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      setInZoom(true)
      parent.classList.add('inZoom');
      container.classList.add('zoomLevel')
      document.addEventListener('click', (event) => { handleZoomOut(event, parent) })
      navigate(slug);
      const images = parent.querySelectorAll(".imageBox img")
      images.forEach(image => {
        image.src = image.dataset.src
      });

      const currentContentContainer = document.getElementById(slug);
      if (currentContentContainer) {
        const frameCover = currentContentContainer.getElementsByClassName('frameCover')[0]
        if (frameCover) {
          frameCover.classList.add('opacity0');
          frameCover.classList.remove('opacity1');
          setTimeout(() => {
            frameCover.style.display = 'none';
          }, 800)
        }
      }
    }
  }

  const handleGalleryClose = () => {
    setIsGalleryActive(false);
    setTimeout(() => {
      document.getElementById(slug).click();
    }, 200)
  }

  const handleGalleryOpen = (index) => {
    zoom.out();
    setTimeout(() => {
      setIsGalleryActive(true);
    }, 400)
    setImageIndex(index);
  }

  const handlePrevision = () => {
    if (imageIndex === 0) {
      setImageIndex(images.length-1);
    }else{
      setImageIndex(current => current-1)
    }
  }

  const handleNext = () => {
    if (imageIndex < images.length-1) {
      setImageIndex(current => current+1)
    }else{
      setImageIndex(0);
    }
  }

  if (type === 'monitor') {
    return (
      <section
        onClick={(e) => handleZoomIn(e)}
        className='monitorContent element frame zoomable'
        id={slug ? slug : ''}
      >
        {logo && (
          <div className='frameCover'>
            <img src={logo} alt={slug} />
          </div>
        )}
        <AboutMe
          text={ReactHtmlParser(text)}
          title={title}
          image={images[0]}
          links={rawLinks}
        />
      </section>
    )
  } else if (type === 'calendar') {
    return (
      <section
        onClick={(e) => handleZoomIn(e)}
        className='calendarContainer element zoomable'
        id={slug ? slug : ''}
      >
        <Consultation isInZoom={inZoom} />
      </section>
    )
  } else if (type === 'contact') {
    return (
      <section
        onClick={(e) => handleZoomIn(e)}
        className='contactContainer element frame zoomable'
        id={slug ? slug : ''}
        style={{
          left: elementPosition.left,
          top: elementPosition.top,
        }}
      >
        <Contact isInZoom={inZoom} />
      </section>
    )
  }
  return (
    <section
      onClick={(e) => handleZoomIn(e)}
      className={cssClasses}
      id={slug ? slug : ''}
      style={{
        left: elementPosition.left,
        top: elementPosition.top,
      }}
      >
      {logo && (
        <div className='frameCover'>
          <img src={logo} alt={slug} />
        </div>
      )}
      <div className="contentContainer">
        {title && <h5><span>{title}</span></h5>}
        <div className="text">
          {text ? ReactHtmlParser(text) : <p className='yourSite'>{t('emptyFrame')}</p>}
        </div>
        {rawLinks && rawLinks.length > 0 && <Links links={rawLinks} />}
        <div className='images'>
          {images && images.length > 0 &&
            images.map((image, index) => (
              imageType === 'mobile' ?
                <MobileFrame handleGalleryOpen={() => handleGalleryOpen(index)} key={image} image={image} alt={title} />
                : (<div key={image} className='imageBox'
                    onClick={() => handleGalleryOpen(index)}>
                      <img data-src={image} src="" alt={title} />
                    </div>
                )
            ))
          }
        </div>
      </div>
      {
        isGalleryActive && images && images.length > 0 && (
          <Gallery
            images={images}
            imageIndex={imageIndex}
            handleClose={handleGalleryClose}
            handleNext={handleNext}
            handlePrevision={handlePrevision}
          />
        )
      }
    </section>
  )
}

export default Content;