import React from 'react';
import { useTranslation } from 'react-i18next';

const SimpleFrame = () => {
  const { t } = useTranslation();
  return (
    <div className='element simpleFrameContainer'>
      <span>
        {t('simpleFrameTitle')}<br /><span className="bigger">{t('simpleFrameText')}</span>
    </span>
    </div>
  )
}

export default SimpleFrame;
