import React, { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

const Switcher = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'en') {
      const switcher = document.getElementById('lightSwitchContainer')
      switcher.classList.add('on');
    }
  }, [])

  const changeLang = () => {
    const nextLanguage = i18n.language === 'hu' ? 'en' : 'hu'
    i18n.changeLanguage(nextLanguage);
    const switcher = document.getElementById('lightSwitchContainer')
    switcher.classList.toggle('on');
  }
  const inactiveLang = i18n.language === 'hu' ? 'en' : 'hu';
 /*  const styleHtml =
  `<style>.switch:before{content: '${i18n.language}' !important}.switch:before{content: '${inactiveLang}' !important}</style>`
 */
  return (
  /*   <img
      onClick={changeLang}
      alt="switcher"
      src={require("../images/png/lampakapcsolo@3x.png").default}
      className="switcher element"
    /> */
    <>
      {/* <div
        onClick={changeLang}
        alt="switcher"
        id="lightSwitchContainer"
        src={require("../images/switcher_down.svg")}
        className="switcher element"
      /> */}
      <div
        id="lightSwitchContainer"
        className="switcher element"
        onClick={changeLang}
      >
        {/* <div className="switch"></div> */}
      </div>
    </>
  )
}

export default Switcher;
