import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const SiteWalkThrough = ({steps}) => {
  const [index, setIndex] = useState(0)
  const [positions, setPositions] = useState({
    top: 0,
    left: 0
  });
  const [elementPosition, setElementPosition] = useState({
    elementWidth: 0,
    elementHeight: 0,
    elementLeft: 0,
    elementTop: 0,
  })
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    getCurrentPositions();
  }, [index])

  const indexHandler = () => {
    if (index < steps.length - 1) {
      setIndex(prev => prev + 1)
    } else {
      setIsActive(false);
      setIndex(0);
    }
  }

  const getCurrentPositions = async () => {
    const margin = 40;
    const currentElement = await document.querySelector(steps[index].target);
    if (currentElement) {
      const elementWidth = currentElement.offsetWidth;
      const elementHeight = currentElement.offsetHeight;
      const elementLeft = currentElement.offsetLeft;
      const elementTop = currentElement.offsetTop;
      setElementPosition({
        elementWidth,
        elementHeight,
        elementLeft,
        elementTop,
      })
      if (steps[index].position === 'top') {
        setPositions({
          left: elementLeft + (elementWidth / 2),
          top: elementTop - margin
        })
      } else if (steps[index].position === 'right') {
        setPositions({
          left: elementLeft + elementWidth + margin,
          top: elementTop + (elementHeight / 2)
        })
      } else if (steps[index].position === 'bottom') {
        setPositions({
          left: elementLeft + (elementWidth / 2),
          top: elementTop + margin
        })
      } else if (steps[index].position === 'left') {
        setPositions({
          left: elementLeft - margin,
          top: elementTop + (elementHeight / 2)
        })
      } else if (steps[index].position === 'middle') {
        setPositions({
          left: elementLeft + (elementWidth / 2) - 20,
          top: elementTop + (elementHeight / 2) - 20
        })
      }
    }
  }

  const handleClickOnSpotLight = () => {
    if (typeof steps[index].handleClickOnSpotLight === "function") {
      steps[index].handleClickOnSpotLight()
    }
    indexHandler();
  }

  return (<>
    {isActive ? (
      <>
        <div
          className={steps[index].alwaysVisible ? 'alwaysVisible stdBox' : 'stdBox'}
          style={{ left: positions.left + "px", top: positions.top + "px" }}
          id={`step${index}`}
        >
          <span
            className="tooltip"
          >
            <button
              className="pinner"
              type="button"
              aria-label="Open the dialog"
              title="Open the dialog"
              data-for="registerTip"
              onClick={handleClickOnSpotLight}
              onMouseEnter={() => {
                document.querySelector('.tooltipText').style.opacity = 1;
              }}
              onMouseLeave={() => {
                document.querySelector('.tooltipText').style.opacity = 0;
              }}
            >
              <span className="innerPinner1"></span>
              <span className="innerPinner2"></span>
            </button>
            <span
              className="tooltipText"
              //style={{maxWidth: elementPosition.elementWidth}}
            >
              {steps[index].title && <h3>{ReactHtmlParser(steps[index].title)}</h3>}
              {steps[index].content && <div>{ReactHtmlParser(steps[index].content)}</div>}
            </span>
          </span>
        </div>
        <div className="swtWrapper">
          <div className="flashLight"
              style={{
                left: elementPosition.elementLeft + "px",
                top: elementPosition.elementTop + "px",
                width: elementPosition.elementWidth + "px",
                height: elementPosition.elementHeight + "px",
              }}
              onClick={handleClickOnSpotLight}
            >
          </div>
        </div>
      </>
        ): (
        <></>
      )}
    </>
  );
};

export default SiteWalkThrough;