import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

const Modal = ({title, description, handleClose}) => {
  const { t } = useTranslation();
  return (
    <div id='modalWrapper'>
      <div className='modal'>
        {title && <h3>{ReactHtmlParser(title)}</h3>}
        {description && <p>{ReactHtmlParser(description)}</p>}
        <button onClick={handleClose}>{t('ok')}</button>
      </div>
    </div>
  )
}

export default Modal;
