import {isMobile} from 'react-device-detect';

export const mouseScroller = ((left) => {
  const panIntervalValue = 800
  const arrow = document.getElementsByClassName('arrow')[0];
  let mouseX = left;
  let mouseY = 0;
  let panUpdateInterval = -1;
  let panEngageTimeout = -1;
  let isMouseOutOfWindow = false;

  document.addEventListener('mousemove', function (event) {
    if (!isMobile) {
      //console.log(event.clientX)
      mouseX = event.clientX;
      mouseY = event.clientY;
    }
  });

  /* let accelerometer = null;
  try {
    accelerometer = new window.LinearAccelerationSensor({ frequency: 6 });
    accelerometer.onerror = (event) => {
      // Handle runtime errors.
      if (event.error.name === 'NotAllowedError') {
        console.log('Permission to access sensor was denied.');
      } else if (event.error.name === 'NotReadableError') {
        console.log('Cannot connect to the sensor.');
      }
    };
    accelerometer.onreading = (e) => {
      if (0.1 < e.currentTarget.x && e.currentTarget.x < 1) {
        mouseX = 20
      } else if (-0.1 > e.currentTarget.x && e.currentTarget.x > -1) {
        mouseX = window.innerWidth - 20;
      } else {
        mouseX = window.innerWidth / 2
      }
    };
    accelerometer.start();
  } catch (error) {
    // Handle construction errors.
    if (error.name === 'SecurityError') {
      console.log('Sensor construction was blocked by the Permissions Policy.');
    } else if (error.name === 'ReferenceError') {
      console.log('Sensor is not supported by the User Agent.');
    } else {
      throw error;
    }
  } */

/*   window.addEventListener('devicemotion', function (event) {
    if (isMobile) {
      let scrollOffset = getScrollOffset();
      mouseX = scrollOffset.x + (event.acceleration.x * 7);
      mouseY = scrollOffset.y + (event.acceleration.y * 7);
    }
  }, true);
 */
  document.addEventListener("mouseleave", (event) => {
    if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      isMouseOutOfWindow = true;
    }
  });
  document.addEventListener("mouseenter", (event) => {
    isMouseOutOfWindow = false;
  });

  function pan() {
    let rangeX = 188.92;
    let rangeY = 180.52;
    if (isMobile) {
      rangeX = 30;
      rangeY = 30;
    }
    let level = 5.61607142857143;
    let scrollOffset = getScrollOffset();
    const container = document.getElementById('container');
    let inZoom = false;
    if (container) {
      inZoom = container.classList.contains('zoomLevel');
    }

		// Up
    if (inZoom) {
      return false;
    }
    if (mouseY < rangeY && !isMouseOutOfWindow) {
      arrowShower(0)
			window.scroll( scrollOffset.x, scrollOffset.y - ( 1 - ( mouseY / rangeY ) ) * ( 14 / level ) );
		}
		// Down
    else if ((mouseY > window.innerHeight - rangeY) && !isMouseOutOfWindow) {
      arrowShower(180)
			window.scroll( scrollOffset.x, scrollOffset.y + ( 1 - ( window.innerHeight - mouseY ) / rangeY ) * ( 14 / level ) );
		}

		// Left
    if (mouseX < rangeX && !isMouseOutOfWindow) {
      const scroll = scrollOffset.x - ( 1 - ( mouseX / rangeX ) ) * ( 14 / level )
      arrowShower(270, (scrollOffset.x+ 50))
			window.scroll( scroll, scrollOffset.y );
		}
		// Right
    else if ((mouseX > window.innerWidth - rangeX) && !isMouseOutOfWindow) {
      //console.log(scrollOffset.x)
      const scroll = scrollOffset.x + ( 1 - ( window.innerWidth - mouseX ) / rangeX ) * ( 14 / level )
      arrowShower(90, (scroll - 150))
			window.scroll( scroll, scrollOffset.y );
    } else {
      arrow.style.opacity = 0;
    }
	}

  function arrowShower(degree, scrollOffsetX = 0) {
    /* if (arrow) {
      arrow.style.left = getScrollOffset().x;
      arrow.style.top = mouseY;
      arrow.style.transform = 'rotate('+degree+'deg)';
      arrow.style.opacity = 0.3
    } */
  }

	function getScrollOffset() {
		return {
			x: window.scrollX !== undefined ? window.scrollX : window.pageXOffset,
			y: window.scrollY !== undefined ? window.scrollY : window.pageYOffset
		}
  }

  panEngageTimeout = setTimeout(function () {
    panUpdateInterval = setInterval( pan, 1000 / 60 );
  }, panIntervalValue );
})