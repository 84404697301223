import React from 'react';
import { FaApple, FaAndroid, FaLaptop } from 'react-icons/fa'

const Links = ({ links: rawLinks }) => {
  const response = rawLinks.map((link) => {
    return (
      <div className={`link ${link.iconType}`} key={link.text}>
        <a target='_blank' href={link.url}>
          <span>{link.text}</span>
        {
          link.iconType === 'apple' ?
            <FaApple color='#fff' size={8}/>
            : link.iconType === 'android' ?
              <FaAndroid color='#fff' size={8}/>
              : <FaLaptop color='#fff' size={8}/>
          }
        </a>
      </div>
    )
  });
  return <div className='linksContainer'>{response}</div>
}

export default Links;
