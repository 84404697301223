import React, { useState, useEffect } from "react";

const useAudio = urls => {
  const [audio, setAudio] = useState(new Audio(urls[Math.floor(Math.random()*urls.length)]));
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    setPlaying(!playing);
  }
  useEffect(() => {
    if (playing) {
      audio.play()
    } else {
      audio.pause();
      const url = urls[Math.floor(Math.random() * urls.length)];
      setAudio(new Audio(url))
    }
  },[playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ urls }) => {
  const [playing, toggle] = useAudio(urls);

  return (
    <div className='player'>
      <button className={playing ? "pause" : "play"} onClick={toggle}></button>
    </div>
  );
};

export default Player;