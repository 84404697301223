import React, { useEffect, useState, useRef } from 'react';
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

const Contact = ({isInZoom, isWithoutText = false}) => {
  //const [isInZoom, setIsInZoom] = useState(false)
  const [imageOpacity, setImageOpacity] = useState(1.0);
  const [formOpacity, setFormOpacity] = useState(1.0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const alert = useAlert();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (isInZoom) {
      setImageOpacity(0);
      setFormOpacity(1)
    } else if(isZoomOutAvailable()) {
      setImageOpacity(1);
    }
    if ((!isInZoom && name === '' && email === '' && message === '')) {
      setFormOpacity(0)
    }
    setIsSuccessMessage(false);
  }, [isInZoom])

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('track', 'Contact');
      }
      if (window.pintrk != null) { 
        window.pintrk('track', 'signup');
      }
    }

    emailjs.sendForm('service_164hqfl', 'template_yzb6qzk', form.current, 'user_ihXKIucBx8P2QsqCbJhs9')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    emailjs.sendForm('service_164hqfl', 'template_c05rdd6', form.current, 'user_ihXKIucBx8P2QsqCbJhs9')
      .then((result) => {
        setEmail('')
        setName('')
        setMessage('')
        setIsSubmitDisabled(false);
        setIsSuccessMessage(true);
        alert.show(t('emailSuccess'));
      }, (error) => {
        console.log(error.text);
        setIsSubmitDisabled(false);
        alert.show(t("emailFail"), {
          title: t("error")
        });
      });
  };


  const isZoomOutAvailable = () => {
    return !isInZoom && name === '' && email === '' && message === ''
  }

  return (
    <div className='element contact'>
      {!isWithoutText && <div className='contactMain' style={{ opacity: imageOpacity }} >
        <img src={require("../images/png/hello@3x.png").default} />
        {t('conatctTitle')}
      </div>}
      {!(!isInZoom && name === '' && email === '' && message === '') &&
        <form style={{ opacity: formOpacity }} ref={form} onSubmit={sendEmail}>
          {!isWithoutText && <>
            <h4>{t('writeToMe')}</h4>
            <span>{t('clickToCalendar')}</span>
          </>}
          {
            isSuccessMessage &&
            <span className='emailSuccessText'>{t('emailSuccess')}</span>
          }
          <input
            type="text"
            name='name'
            id='name'
            placeholder={t('yourName')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            lang={i18n.language}
          />
          <input
            type="email"
            name='email'
            id='email'
            placeholder={t('yourEmail')}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            lang={i18n.language}
          />
          <textarea
            rows={5}
            name='message'
            placeholder={t('contactTextArea')}
            value={message} onChange={(event) => setMessage(event.target.value)} 
            lang={i18n.language}
          />
          <input
            disabled={isSubmitDisabled}
            type="submit"
            value={t('send')} />
        </form>
      }
    </div>
  )
}

export default Contact;
