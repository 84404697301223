import React from 'react';

const Lamp = () => {
  const handleLampClick = () => {
    const lamp = document.getElementById('lightButton');
    lamp.classList.toggle('on');
  }

  return (
    <>
      <img
        alt="lamp"
        src={require("../images/png/asztali-lampa@3x.png").default}
        className="lamp element"
        id="lamp"
      />
      <div
        id="lightButton"
        onClick={handleLampClick}
      ></div>
      <div className='light'></div>
    </>
  )
}

export default Lamp;
