import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next'
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi'
import emailjs from '@emailjs/browser';
import Calendar from 'react-calendar';
import ReactHtmlParser from 'react-html-parser';
import { useAlert } from 'react-alert'
import 'react-calendar/dist/Calendar.css';

const Consultation = ({isInZoom}) => {
  const today = new Date()
  const { i18n, t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(today);
  const [calendarStep, setCalendarStep] = useState(0);
  const [calendarOpacity, setCalendarOpacity] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("")
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const alert = useAlert();
  //const date = new Date().toLocaleString(i18n.language, { day: "numeric", month: "long" });
  const form = useRef();
  const todayTimeStamp = today.getTime()
  const firstDayOfCurrentMonth =
    new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDayOfCurrentYear =
      new Date(today.getFullYear(), 0, 1);

  const handleDateSelect = (date) => {
    console.log(date);
    setSelectedDate(date);
    changeStep(2)
  }

  useEffect(() => {
    if (isInZoom) {
      setCalendarStep(0)
      setCalendarOpacity(1);
    } else {
      setCalendarStep(1)
      setCalendarOpacity(1);
      setIsSuccessMessage(false);
    }
  }, [isInZoom])

  const changeStep = (toStep) => {
    const calendarOpacity = toStep === 1 ? 1 : 0.1
    const timeoutTime = toStep === 1 ? 0 : 800
    setTimeout(() => {
      setCalendarStep(toStep);
    }, timeoutTime)
    setCalendarOpacity(calendarOpacity);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    console.log(form.current)

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Lead');
      }
      if (window.gtag_report_conversion != null) {
        //window.gtag_report_conversion('https://dewises.com')
      }
      if (window.pintrk != null) {
        window.pintrk('track', 'signup');
      }
    }

    emailjs.sendForm('service_164hqfl', 'template_yzb6qzk', form.current, 'user_ihXKIucBx8P2QsqCbJhs9')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    emailjs.sendForm('service_164hqfl', 'template_c05rdd6', form.current, 'user_ihXKIucBx8P2QsqCbJhs9')
      .then((result) => {
        setEmail('')
        setName('')
        setTime('')
        setMessage('')
        setSelectedDate(today)
        setIsSubmitDisabled(false);
        setIsSuccessMessage(true);
        //alert.show(t('emailSuccess'));
        setCalendarStep(1);
      }, (error) => {
        console.log(error.text);
        setIsSubmitDisabled(false);
        alert.show(t("emailFail"), {
          title: t("error")
        });
        setCalendarStep(1);
      });
  };

  const getAvailableTimes = () => {
    let times = []
    for (let i = 8; i < 20; i++){
      times.push(`${i.toString()}:00`)
      times.push(`${i.toString()}:30`)
    }
    return times;
  }

  return (
    <div>
      {calendarStep !== 2 && <h3 id="calendarTitle">{t('calendarTitle')}</h3>}
      <div className='frameCover'>
      </div>
      <img
      alt="calendar"
      src={require("../images/png/calendar@3x.png").default}
      className="calendar"
      />
      {
        isSuccessMessage &&
        <span className='emailSuccessText'>{t('consultantSuccess')}</span>
      }
      {
        calendarStep > 0 && (
          <div className='back arrow' onClick={() => {changeStep(calendarStep-1)}}>
            <HiOutlineArrowNarrowLeft size={13} />
          </div>
        )
      }
      {
        calendarStep === 0 && (
          <div className='next arrow' onClick={() => {changeStep(calendarStep+1)}}>
            <HiOutlineArrowNarrowRight size={13} />
          </div>
        )
      }
      {calendarStep === 0 && (
        <div id='calendarIntroduce'>
          <div>
            {ReactHtmlParser(t("calendarIntroduce"))}
            <button onClick={() => { changeStep(1) }}>{t('go')}</button>
          </div>
        </div>
      )}
      {calendarStep === 1 &&
        <div
          id='rcCalendarContainer'
          className='calendarStep1 calendarStep'
          style={{opacity: calendarOpacity}}
        >
          <span className="selectDate">{t('selectDate')}</span>
          <Calendar
            locale={i18n.language}
            onChange={handleDateSelect}
            value={selectedDate}
            defaultView='month'
            tileDisabled={({ activeStartDate, date, view }) => {
              if (view === 'month') {
                return date.getTime() < todayTimeStamp || date.getDay() > 5 || date.getDay() === 0
              } else if (view === 'year') {
                return date.getTime() < firstDayOfCurrentMonth.getTime()
              } else if (view === 'decade') {
                console.log(date, firstDayOfCurrentYear);
                return date.getTime() < firstDayOfCurrentYear.getTime()
              } /* else if (view === 'century') {
                console.log(date);
                return date.getTime() < firstDayOfCurrentYear.getTime()
              } */
            }}
          />
        </div>
      }
      {
        calendarStep === 2 && (
          <div className='calendarStep2 calendarStep'>
            {/* <div className='backCalendar' onClick={() => {changeStep(1)}}>
              <HiOutlineArrowNarrowLeft size={13} />
            </div> */}
            <h5>{selectedDate.toLocaleString(i18n.language, { day: "numeric", month: "long" })}</h5>
            <form ref={form} onSubmit={sendEmail}>
              <select
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              >
                <option>{t('time')}</option>
                {getAvailableTimes().map(time => <option kex={time} value={time}>{time}</option>)}
              </select>
              <input
                type="hidden"
                name='date'
                id='date'
                value={selectedDate}
              />
              <input
                type="hidden"
                name='time'
                id='time'
                value={time}
              />
              <input
                type="text"
                name='name'
                id='name'
                placeholder={t('yourName')}
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
              <input
                type="text"
                name='email'
                id='email'
                placeholder={t('yourEmail')}
                value={email}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
              <textarea
                rows={4}
                name='message'
                placeholder={t('contactTextArea')}
                value={message} onChange={(event) => setMessage(event.target.value)} 
                lang={i18n.language}
              />
              <input
                disabled={isSubmitDisabled}
                type="submit"
                value={t('send')}
              />
            </form>
          </div>
        )
      }

  </div>
  )
}

export default Consultation;
